import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import media from './Media'
import {
  FormGroup,
  FormLabel,
  Input,
  TextArea,
  Optional,
  FormGroup2col,
  Select,
} from '../components/FormStyles'
import { useSpring, animated } from 'react-spring'

import NavBar from './NavBar'

const HeaderWrapper = styled.header`
  background: linear-gradient(135deg, #6f1c7c 0%, #d35773 77%, #f86d70 100%);
`
const HeaderContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: ${props => props.theme.maxwidth};
  margin: 0 auto;
  color: #fff;
  @media ${media.tablet} {
    
    flex-wrap: nowrap;
  }
  h1 {
    @media ${media.tablet} {
      font-size: 4.5rem;
      line-height: 4.5rem;
    }
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
  p {
    font-family: 'Raleway', sans-serif;
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
`
const HeaderColLeft = styled(animated.div)`
  flex-grow: 1;
  flex-shrink: 1;
  padding: 1rem 1rem 0 2rem;
  margin-top: 1rem;
  flex-basis:400px;
  @media ${media.tablet} {
    margin-top: 4rem;
    
  }
  
`

const HeaderColRight = styled(animated.div)`
  flex-grow: 1;
  flex-shrink: 1
  flex-basis:400px;
  
`

const Button = styled.button`
  background: #fff;
  width: 100%;
  padding: 1rem;
  cursor: pointer;
`

const Form = () => {
  const [focus, setFocus] = useState(false)

  useEffect(() => setFocus(true)),[]
  return (
    <form
      method="post"
      action="#"
      data-netlify="true"
      name="contact"
      style={{ padding: '2rem' }}
    >
      
      <FormGroup>
        <Input type="hidden" name="form-name" value="contact" />
      </FormGroup>
      <FormGroup>
        <FormLabel htmlFor="name">
          Name:
          <span className="required">*</span>
        </FormLabel>
        <Input
          type="text"
          name="name"
          id="name"
          required
          autoFocus
          className={focus ? 'focus' : ''}
          placeholder="e.g Bob Smith"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel htmlFor="email">
          Email:
          <span className="required">*</span>
        </FormLabel>
        <Input type="text" name="email" id="email" required placeholder="e.g bob@gmail.com"/>
      </FormGroup>
      <FormGroup>
        <FormLabel htmlFor="message">
          Message:
          <span className="required">*</span>
        </FormLabel>
        <TextArea name="message" id="message" rows="4" required placeholder="e.g I would love to talk to you about increasing my website revenue"/>
      </FormGroup>
      <FormGroup>
        <Input type="submit" value="Send Message"/>
        
        
      </FormGroup>
    </form>
  )
}

const Header = ({ title, sub, image, form }) => {
  const fade = useSpring({ opacity: 1, from: { opacity: 0 } })

  return (
    <HeaderWrapper>
      <NavBar />
      <HeaderContent>
        <HeaderColLeft style={fade}>
          <h1 style={fade}>{title}</h1>
          <p>{sub}</p>
          {form && <div>Or call us on +64 212 380 847</div>}
        </HeaderColLeft>
        <HeaderColRight style={fade}>
          {form ? <Form /> : <img src={image} alt="Celebrate your brand" />}
        </HeaderColRight>
      </HeaderContent>
    </HeaderWrapper>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
