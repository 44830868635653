import React, { useState } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { Link } from 'gatsby';
import Logo from './Logo';
import media from './Media';

const NavBarWrapper = styled.nav`
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.globalPadding};
`;
const Nav = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
  flex-basis: auto;
  display: none;
  @media ${media.tablet} {
    text-align: right;
    display: block;
  }
`;

const NavLink = styled(Link)`
  display: block;
  color: #fff;
  padding: 0.5rem;
  font-size: 2rem;
  @media ${media.tablet} {
    display: inline;
    font-size: 1rem;
  }
`;
const Toggle = styled.a`
  color: #fff;
  cursor: pointer;
  display: inline-block;
  @media ${media.tablet} {
    display: none;
  }
`;
const MobNav = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  background: #333;
  height: 100vh;
  width: 100vw;
  padding: 4rem 2rem;
`;

const Close = styled.a`
  position: absolute;
  cursor: pointer;
  top: 30px;
  right: 55px;
  color: #fff;
`;
const Navigation = () => (
  <Nav>
    {/* <NavLink to="/about">About us</NavLink> */}
    <NavLink to="/services">Services</NavLink>
    <NavLink to="/clients">Clients</NavLink>
    <NavLink to="/contact">Contact</NavLink>
  </Nav>
);

const MobMenu = ({ style, toggle }) => (
  <animated.div style={style}>
    <MobNav>
      <Close onClick={toggle}>Close</Close>
      <NavLink to="/services">Services</NavLink>
      <NavLink to="/clients">Clients</NavLink>
      <NavLink to="/contact">Contact</NavLink>
    </MobNav>
  </animated.div>
);

const NavBar = () => {
  const [toggle, setToggle] = useState(false);

  const mobAnimation = useSpring({
    transform: toggle ? 'translate3D(0,0,0) scale(1)' : 'translate3D(100%,0,0) scale(0)',
  });

  return (
    <>
      {toggle && <MobMenu style={mobAnimation} toggle={() => setToggle(false)} />}
      <NavBarWrapper>
        <Logo />
        <Toggle onClick={() => setToggle(!toggle)}>Menu</Toggle>
        <Navigation />
      </NavBarWrapper>
    </>
  );
};

export default NavBar;
