import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Link } from 'gatsby'
import logo from '../images/powerlogo.svg'
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const LogoWrapper = styled.div`
  max-width: 200px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 200px;
`

const Logo = () => (
  <LogoWrapper>
    <Link to="/">
      <img
        src={logo}
        style={{ margin: '10px 0 0' }}
        alt="Powerboard Digital marketing"
      />
    </Link>
  </LogoWrapper>
)
export default Logo
