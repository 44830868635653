const defaultTheme = {
  id: 'base',

  backgroundColor: 'white',
  primaryColor: '#6c63ff', //base red #F82A2A'
  secondaryColor: '#f86d70', //aqua blue
  light: '#ecebff',
  white: '#ffffff',
  black: '#0E0E0E',

  charcol: '#333',
  maxwidth: '1400px',
  shadow: '9px 8px 7px -2px rgba(227,224,227,0.84)',

  globalPadding: '1rem 2rem',
}

export default defaultTheme
