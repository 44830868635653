import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import styled from 'styled-components'

//Theme and CSS
import defaultTheme from './theme/default'
import { GlobalStyle } from './theme/GlobalStyles'

const Footer = styled.footer`
  padding: ${props => props.theme.globalPadding};
`
const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={defaultTheme}>
        <>
          <GlobalStyle />

          <main>{children}</main>
          <Footer
            style={{
              maxWidth: '1440px',
              margin: '1rem auto',
            }}
          >
            © Powerboard Digital Marketing {new Date().getFullYear()}
          </Footer>
        </>
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
